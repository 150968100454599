import {
    FETCH_USER_PROFILE,
    FAILURE_USER_PROFILE,
    SET_USER_APPLICATION
} from '../actions/LoginAction';

const initialState = {
    loggedInUserProfile: null,
    selectedUserApplication: null
};

const LoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_PROFILE:
            const loggedInUserProfile = {
                ...action.payload
            };

            return {
                ...state,
                loggedInUserProfile: loggedInUserProfile
            };
        case FAILURE_USER_PROFILE:
            return {
                ...state,
                loggedInUserProfile: null
            };
        case SET_USER_APPLICATION:
            return {
                ...state,
                selectedUserApplication: action.payload
            };
        default:
            return state;
    }
};

export default LoginReducer;