import React from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {
    Grid,
    Tooltip,
    Avatar
} from '@material-ui/core';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
// import _ from 'lodash';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import SchoolIcon from '@material-ui/icons/School';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


import RestorePageIcon from '@material-ui/icons/RestorePage';
import PublishIcon from '@material-ui/icons/Publish';
import EventNoteIcon from '@material-ui/icons/EventNote';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import { FIGMD_DASHBOARD_PAGE_LOGO, ALL_ROUTES } from '../../constants.js';

const drawerWidth = 270;
const useStyles = makeStyles((theme) => ({
    img: {
        height: '3.25rem',
        paddingTop: '5px',
        paddingBottom: '5px',
    },
    jitoLogo: {
        width: '130px',
        height: '4rem',
        marginRight: "2.5rem !important"
    },
    header: {
        backgroundColor: '#fff',
        boxShadow: '0 1px 0 1px rgba(69, 65, 78, 0.1)',
        zIndex: theme.zIndex.drawer + 1
    },
    root: {
        display: 'flex',
    },
    appBar: {
        // zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 24,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(8) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    profileAvtar: {
        textTransform: 'uppercase',
        width: '45px',
        height: '45px',
        padding: '2px',
        fontSize: "1rem"
    }
}));

function SimpleMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logOutHandler = async () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = ALL_ROUTES.LOGIN;
    }

    const profileClickHandler = () => {
        props.history.push(`${ALL_ROUTES.USER_PROFILE}`);
    }

    const { classes, loggedInUserProfile } = props;
    const firstName = (loggedInUserProfile && loggedInUserProfile.firstname && loggedInUserProfile.firstname.length) ? loggedInUserProfile.firstname[0] : "";
    const lastName = (loggedInUserProfile && loggedInUserProfile.lastname && loggedInUserProfile.lastname.length) ? loggedInUserProfile.lastname[0] : "";
    const avatarText = firstName + lastName;

    return (
        <Grid container className="profile-btn-container">
            <IconButton
                aria-label="account of current user"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                color="primary"
            >
                {
                    avatarText === "" ? (
                        <Avatar className={classes.profileAvtar}></Avatar>
                    ) : (
                        <Avatar className={classes.profileAvtar}>{`${avatarText}`}</Avatar>
                    )
                }
            </IconButton>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {/* <MenuItem onClick={() => profileClickHandler()}>Profile</MenuItem> */}
                <MenuItem onClick={() => logOutHandler()}>Logout</MenuItem>
            </Menu>
        </Grid>
    );
}

function MenuList(props) {
    // const { loggedInUserProfile } = _.cloneDeep(props);
    // const menuList = (loggedInUserProfile && loggedInUserProfile.menu && loggedInUserProfile.menu.length) ? loggedInUserProfile.menu : [];
    let menuList = [];

    menuList.push({
        icon: "HomeWorkIcon",
        listorder: 1,
        menuid: 1,
        route: "/landingPage",
        title: "Landing Page"
    })

    menuList.push({
        icon: "HomeIcon",
        listorder: 2,
        menuid: 2,
        route: "/dashboard",
        title: "Dashboard"
    })

    const getIcon = (icon) => {
        let IconComponent = null;
        switch (icon) {
            case 'HomeIcon':
                IconComponent = <HomeIcon />;
                break;
            case 'HomeWorkIcon':
                IconComponent = <HomeWorkIcon />;
                break;
            case 'PersonAddIcon':
                IconComponent = <PersonAddIcon />;
                break;
            case 'RestorePageIcon':
                IconComponent = <RestorePageIcon />;
                break;
            case 'PublishIcon':
                IconComponent = <PublishIcon />;
                break;
            case 'EventNoteIcon':
                IconComponent = <EventNoteIcon />;
                break;
            case 'QueuePlayNextIcon':
                IconComponent = <QueuePlayNextIcon />;
                break;
            case 'SchoolIcon':
                IconComponent = <SchoolIcon />;
                break;
            case 'fas fa-syringe':
                IconComponent = <i className="fas fa-syringe"></i>;
                break;
            case 'AccountBalanceIcon':
                IconComponent = <AccountBalanceIcon />
                break;
            case 'LibraryBooksIcon':
                IconComponent = <LibraryBooksIcon />
                break;
            default:
                break;
        }

        return IconComponent;
    }

    return (
        <List>
            {
                (menuList).map((row, index) => (
                    <ListItem
                        button
                        key={row.menuid}
                        onClick={() => props.handleMenuClick(row.route)}
                        className={`leftmenu-icons ${props.history.location.pathname === row.route ? 'menuActive' : ""}`}
                    >
                        <Tooltip title={row.title} aria-label={row.title} className="menu-tooltip">
                            <ListItemIcon>
                                {getIcon(row.icon)}
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={row.title} />
                    </ListItem>
                ))
            }
        </List>
    )
}

export default function Header(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const LogoImage = FIGMD_DASHBOARD_PAGE_LOGO;

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenuClick = (path) => {
        props.history.push(path);
    }

    // const { loggedInUserProfile } = _.cloneDeep(props);
    // const isAdminUser = (loggedInUserProfile && loggedInUserProfile.userrole && loggedInUserProfile.userrole && loggedInUserProfile.userrole.rolename && loggedInUserProfile.userrole.rolename === 'Admin') ? true : false;

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.header}>
                <Toolbar className='header'>
                    <IconButton
                        color="default"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, 'menu-button', {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>

                    <IconButton
                        color="default"
                        aria-label="close drawer"
                        onClick={handleDrawerClose}
                        edge="start"
                        className={clsx(classes.menuButton, 'menu-button', {
                            [classes.hide]: !open,
                        })}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                    <Grid className='header-wrapper' item xs={12}>
                        {
                            LogoImage ? (
                                <React.Fragment>
                                    <img src={LogoImage} alt='No Found' className={classes.img} />
                                </React.Fragment>
                            ) : null
                        }
                    </Grid>

                    <Grid item className="profile-btn-wrapper">
                        <SimpleMenu {...props} classes={classes} />
                    </Grid>
                </Toolbar>
            </AppBar>

            <Drawer
                // variant="permanent"
                anchor='left'
                className={
                    'jh-aside-left__expand ' +
                    clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
                open={open}
                onClose={() => handleDrawerClose()}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />
                <MenuList {...props} handleMenuClick={handleMenuClick} />
            </Drawer>
        </div >
    )
}