import {
    fetchUserProfileAPI
} from '../services/LoginService';
import {
    handleUnauthorizedRequest
} from '../../components/CommonFunctions.js';

export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const FAILURE_USER_PROFILE = 'FAILURE_USER_PROFILE';
export const SET_USER_APPLICATION = 'SET_USER_APPLICATION';

const receiveUserProfile = (payload) => ({
    type: FETCH_USER_PROFILE,
    payload
});

const failureUserProfile = () => ({
    type: FAILURE_USER_PROFILE
});

export const fetchUserProfile = (params) => {
    return async dispatch => {
        await fetchUserProfileAPI(params)
            .then(response => {
                if (response && response.data && response.data.success && response.data.success.profile) {
                    dispatch(receiveUserProfile(response.data.success.profile));
                } else {
                    dispatch(failureUserProfile());
                }
            })
            .catch(ex => {
                if (ex && ex.response && ex.response.status) {
                    handleUnauthorizedRequest(ex.response.status);
                }
                dispatch(failureUserProfile());
            });
    };
}

const setUserApplication = (payload) => ({
    type: SET_USER_APPLICATION,
    payload
});

export const setUserApplicationToRedux = (params) => {
    return async dispatch => {
        dispatch(setUserApplication(params));
    };
}