export const API_GATEWAY_URL = process.env.API_GATEWAY_URL;
export const CRYPTR_ASE_KEY = process.env.CRYPTR_ASE_KEY;
export const FIGMD_LOGIN_PAGE_LOGO = '/mro-corp-logo.svg'
export const FIGMD_DASHBOARD_PAGE_LOGO = '/mro-corp-logo.svg'

// APIs
export const CHECK_LOGIN = '/vendors/checklogin'
export const GET_USER_PROFILE = '/api/profile'
export const FORGOT_PASSWORD = '/api/forgotpassword'
export const FETCH_ALL_REQUEST_COUNT = '/vendors/fetchTotalRequestCount/FHIR'
export const FETCH_ALL_HEALTH_SYSTEMS = '/vendors/fetchAllHealthSystems/FHIR'
export const FETCH_ALL_HEALTH_SYSTEM_WISE_SUMMARY_COUNT = '/vendors/fetchAllHealthSystemwiseSummaryCount/FHIR'
export const FETCH_ALL_REQUESTS = "/vendors/fetchAllRequests/FHIR"
export const DOWNOAD_PDF_USING_SIGNED_URL = "/vendors/patientSignedURL"
export const FETCH_JOB_SUMMARY = "/vendors/patientjobsummaryrequest"

export const ALL_ROUTES = {
    'LOGIN': "/",
    'DASHBOARD': "/dashboard",
    'LANDING_PAGE': '/landingPage'
}

export const FILE_STATUS = {
    "Inprogress": [3, 12, 14, 4],
    "Inqueue": [1],
    "Patient Not Found": [16],
    "Multiple Patients Found": [21],
    "Encounter Not Found": [22],
    "Document Not Found": [23],
    "Processed": [5, 24],
    "Failed": [18, 2],
    "FYI Flag Found": [25]
}

export const seriesColummList = [
    { label: "Patient Not Found", value: "Patient Not Found", field: "Patient not Found", name: "Patient not Found", color: "orange" },
    { label: "Multiple Patients Found", value: "Multiple Patients Found", field: "Multiple Patients Found", name: "Multiple Patients Found", color: "purple" },
    { label: "Encounter Not Found", value: "Encounter Not Found", field: "Encounter not Found", name: "Encounter not Found", color: "yellow" },
    { label: "Document Not Found", value: "Document Not Found", field: "Document not Found", name: "Document not Found", color: "brown" },
    { label: "FYI Flag Found", value: "FYI Flag Found", field: "FYI Flag Found", name: "FYI Flag Found", color: "grey" },            
    { label: "Failed", value: "Failed", field: "Failed", name: "Failed", color: "red" },
    { label: "Processed", value: "Processed", field: "Processed", name: "Processed", color: "green" },
    { label: "In queue", value: "Inqueue", field: "In queue", name: "In queue", color: "lightgreen" },
    { label: "In progress", value: "Inprogress", field: "In progress", name: "In progress", color: "lightblue" }
]

export const dateFilter = [
    { label: "All", value: "All" },
    { label: "30 Min", value: "30_Min" },
    { label: "60 Min", value: "60_Min" },
    { label: "8 Hours", value: "8_Hours" },
    { label: "12 Hours", value: "12_Hours" },
    { label: "Today", value: "Today" },
    { label: "4 Days", value: "Four_Days" },
    { label: "7 Days", value: "Seven_Days" },
    { label: "Custom Date", value: "Custom" }
]

export const filterFields = [
    {
        label: "ROI Request ID",
        value: "",
        responseValue: "requesterrefid",
        id: "requesterrefid",
        type: "string",
        md: 2,
        xs: 12
    },
    {
        label: "Vendor Request ID",
        value: "",
        responseValue: "vendorrequestid",
        id: "vendorrequestid",
        type: "string",
        md: 2,
        xs: 12
    },
    {
        label: "Request ID",
        value: "",
        responseValue: "requestid",
        id: "requestid",
        type: "string",
        md: 2,
        xs: 12
    },    
    {
        label: "From Date",
        value: "",
        responseValue: "fromdate",
        id: "fromdate",
        type: "date",
        isReadOnly: false,
        md: 2,
        xs: 12
    },
    {
        label: "To Date",
        value: "",
        responseValue: "todate",
        id: "todate",
        type: "date",
        isReadOnly: false,
        md: 2,
        xs: 12
    },
    {
        label: "Select Status",
        value: null,
        responseValue: "filestatus",
        id: "filestatus",
        type: "select",
        isReadOnly: false,
        options: seriesColummList,
        isMultiple: false,
        md: 2,
        xs: 12
    }
];

export const FILTER_TYPES = [
    { label: "EHR", value: "EHR_WISE" },
    { label: "Purpose of use", value: "PURPOSE_OF_USE_WISE" },
    { label: "Facility", value: "FACILITY_WISE" },
    { label: "Location", value: "LOCATION_WISE" }
]