import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './assets/less/main.less';
import reportWebVitals from './reportWebVitals';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import rootReducer from './redux/reducers';
import thunk from 'redux-thunk';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import {
  checkLogin
} from './components/CommonFunctions';
import {
  ALL_ROUTES
} from './constants';

// For Dev and POC environment
const msalConfig = {
  auth: {
    tenantId: "808444af-4011-40d5-9b0a-a9a5c95f88e9", // Your tenant ID goes here
    clientId: "4c96e64f-d45d-4119-ba35-4fce9f7d1770", // Your client (application) ID goes here
    authority: "https://login.microsoftonline.com/808444af-4011-40d5-9b0a-a9a5c95f88e9", // Replace the last part with your tenant ID
    redirectUri: process.env.SSO_REDIRECT_URL, // Must be the same in Azure AD portal, can be replace with an environment variable: process.env.REACT_APP_REDIRECT_URL
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
}

// For Production environment
// const msalConfig = {
//   auth: {
//     tenantId: "808444af-4011-40d5-9b0a-a9a5c95f88e9", // Your tenant ID goes here
//     clientId: "ccb97ad1-9d44-434f-9a7e-c34d9f4a7ee1", // Your client (application) ID goes here
//     authority: "https://login.microsoftonline.com/808444af-4011-40d5-9b0a-a9a5c95f88e9", // Replace the last part with your tenant ID
//     redirectUri: process.env.SSO_REDIRECT_URL, // Must be the same in Azure AD portal, can be replace with an environment variable: process.env.REACT_APP_REDIRECT_URL
//     postLogoutRedirectUri: "/",
//     navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
//   },
//   cache: {
//     cacheLocation: 'sessionStorage',
//     storeAuthStateInCookie: false
//   }
// }

const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0])
}

msalInstance.addEventCallback(async (event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    console.log("LOGIN_SUCCESS........!")
    const name = (event && event.payload && event.payload.account && event.payload.account.name) ? event.payload.account.name : ""
    const username = (event && event.payload && event.payload.account && event.payload.account.username) ? event.payload.account.username : ""
    const firstname = (name && typeof name === "string" && name.split(" ").length > 0) ? name.split(" ")[0]: ""
    const lastname = (name && typeof name === "string" && name.split(" ").length > 1) ? name.split(" ")[1]: ""

    localStorage.setItem('sso-token', event.payload.accessToken);
    const responseData = await checkLogin(firstname, lastname, username, username, true)
    localStorage.setItem('token', responseData);
    msalInstance.setActiveAccount(account)
    window.location.href = ALL_ROUTES.LANDING_PAGE;
  }
})

const store = createStore(rootReducer, applyMiddleware(thunk))

const theme = createTheme({
  palette: {
    primary: { main: '#0098da' },   // Purple and green play nicely together.
    secondary: { main: '#6ABC6A' }  // This is just green.A700 as hex.
  }
});

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App instance={msalInstance} />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
