import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import {
    Button,
    Grid,
    Box,
    CircularProgress,
    TextField,
    Container,
    Typography,
    Link
} from '@material-ui/core';
import _ from 'lodash';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import Snackbar from '../../components/Snackbar/Snackbar';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import {
    fetchUserProfile
} from '../../redux/actions/LoginAction';
import { connect } from 'react-redux';
import {
    API_GATEWAY_URL,
    CHECK_LOGIN,
    CRYPTR_ASE_KEY,
    ALL_ROUTES,
    FIGMD_LOGIN_PAGE_LOGO,
    FORGOT_PASSWORD
} from '../../constants';

const style = {};

const EMAILREGX = RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {
                userName: {
                    isError: false,
                    errorMessage: "Field is mandatory"
                },
                password: {
                    isError: false,
                    errorMessage: "Field is mandatory"
                },
                emailAddress: {
                    isError: false,
                    errorMessage: "Field is mandatory"
                }
            },
            fields: {
                userName: "",
                password: "",
                emailAddress: ""
            },
            showLoader: false,
            showLoginErrorMessage: false,
            loginErrorMessage: "",
            isFormSubmitted: false,

            forgotPassword: false,
            showForgotPasswordSuccessMessage: false,
            forgotPasswordSuccessMessage: "Link has been sent to your registered email address",
            showForgotPasswordErrorMessage: "",
            forgotPasswordErrorMessage: "Something went wrong. Please try again",

            homeBannerImage: FIGMD_LOGIN_PAGE_LOGO,
            isAuthorizedRoute: true
        }
    }

    handleChange = async (e, fieldName) => {
        let { fields } = _.cloneDeep(this.state);

        let fieldValue = e.target.value;
        fields[fieldName] = fieldValue;

        await this.setState({ fields });
        this.validateForm();
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        await this.setState({ isFormSubmitted: true });
        this.validateForm(true);
    }

    validateForm = (isSubmit = false, isForgotPassword) => {
        let { userName, password, emailAddress } = _.cloneDeep(this.state.fields);
        let { errors, isFormSubmitted } = _.cloneDeep(this.state);

        userName = userName.trim();
        password = password.trim();
        emailAddress = emailAddress.trim();

        if (userName.length === 0) {
            errors['userName'].isError = true;
            errors['userName'].errorMessage = "Field is mandatory"
        } else {
            errors['userName'].isError = false;
        }

        if (password.length === 0) {
            errors['password'].isError = true;
            errors['password'].errorMessage = "Field is mandatory"
        } else {
            errors['password'].isError = false;
        }

        if (emailAddress.length === 0) {
            errors['emailAddress'].isError = true;
            errors['emailAddress'].errorMessage = "Field is mandatory"
        } else if (!EMAILREGX.test(emailAddress)) {
            errors['emailAddress'].isError = true;
            errors['emailAddress'].errorMessage = "Please enter valid email address"
        } else {
            errors['emailAddress'].isError = false;
        }

        if (isSubmit || isFormSubmitted) {
            this.setState({ errors });
        }

        if (isSubmit && isForgotPassword && !errors['userName'].isError) {
            this.setState({ showLoader: true }, this.sendResetPasswordLink());
        } else {
            if (isSubmit && !errors['userName'].isError && !errors['password'].isError) {
                this.setState({ showLoader: true });
            }
        }
    }

    checkLogin = () => {
        const { userName, password } = _.cloneDeep(this.state.fields);
        var cryptedPassword = CryptoJS.AES.encrypt(password, CRYPTR_ASE_KEY).toString();
        const data = {
            "username": userName,
            "password": cryptedPassword
        };
        axios({
            method: 'POST',
            url: `${API_GATEWAY_URL}${CHECK_LOGIN}`,
            'headers': {
                'content-type': 'application/json'
            },
            data: data
        })
            .then(async response => {
                if (response && response.data && response.data.error && response.data.error.message) {
                    this.setState({
                        showLoginErrorMessage: true,
                        showLoader: false,
                        loginErrorMessage: response.data.error.message
                    });
                } else if (response && response.data && response.data.success && response.data.success.token) {
                    await this.props.fetchUserProfile(response.data.success.token);
                    localStorage.clear();
                    localStorage.setItem('token', response.data.success.token);
                    this.props.history.push(ALL_ROUTES.LANDING_PAGE);
                } else {
                    this.setState({
                        showLoginErrorMessage: true,
                        showLoader: false,
                        loginErrorMessage: "Something went wrong."
                    });
                }
            })
            .catch(error => {
                this.setState({
                    showLoginErrorMessage: true,
                    showLoader: false,
                    loginErrorMessage: "Something went wrong."
                });
            })
    }

    closeErrorNotificationAfterSetTimeOut = () => {
        const duration = 4000;
        setTimeout(async () => {
            await this.setState({
                showLoginErrorMessage: false,
                showForgotPasswordSuccessMessage: false,
                showForgotPasswordErrorMessage: false
            });
        }, duration);
    };

    forgotPasswordHandler = () => {
        const fields = {
            userName: "",
            password: "",
            emailAddress: ""
        };

        const errors = {
            userName: {
                isError: false,
                errorMessage: "Field is mandatory"
            },
            password: {
                isError: false,
                errorMessage: "Field is mandatory"
            },
            emailAddress: {
                isError: false,
                errorMessage: "Field is mandatory"
            }
        };

        this.setState({
            forgotPassword: true,
            fields,
            errors,
            showLoader: false
        });
    }

    handleBack = () => {
        const fields = {
            userName: "",
            password: "",
            emailAddress: ""
        }

        const errors = {
            userName: {
                isError: false,
                errorMessage: "Field is mandatory"
            },
            password: {
                isError: false,
                errorMessage: "Field is mandatory"
            },
            emailAddress: {
                isError: false,
                errorMessage: "Field is mandatory"
            }
        };

        this.setState({
            forgotPassword: false,
            fields,
            errors,
            showLoader: false,
            showForgotPasswordErrorMessage: false,
            showForgotPasswordSuccessMessage: false
        });
    }

    sendLinkHandler = () => {
        this.validateForm(true, true);
    }

    sendResetPasswordLink = () => {
        const { userName } = _.cloneDeep(this.state.fields);

        const params = {
            "username": userName
        };

        axios({
            method: 'POST',
            url: `${API_GATEWAY_URL}${FORGOT_PASSWORD}`,
            'headers': {
                'content-type': 'application/json'
            },
            data: params
        })
            .then(async response => {
                console.log('Response: ', response);
                if (response && response.data && response.data.error && response.data.error.message) {
                    this.setState({
                        showForgotPasswordErrorMessage: true,
                        forgotPasswordErrorMessage: response.data.error.message,
                        showLoader: false
                    })
                } else if (response && response.data && response.data.success) {
                    this.setState({
                        showForgotPasswordSuccessMessage: true
                    });

                    const duration = 3000;
                    setTimeout(async () => {
                        this.handleBack();
                    }, duration);
                } else {
                    this.setState({
                        showForgotPasswordErrorMessage: true,
                        forgotPasswordErrorMessage: "Something went wrong. Please try again.",
                        showLoader: false
                    })
                }
            })
            .catch(error => {
                this.setState({
                    showForgotPasswordSuccessMessage: false,
                    showForgotPasswordErrorMessage: false,
                    forgotPasswordErrorMessage: "Something went wrong. Please try again.",
                    showLoader: false
                })
            })
    }

    onCloseSnackBar = () => {
        this.setState({
            showLoginErrorMessage: false,
            showForgotPasswordSuccessMessage: false,
            showForgotPasswordErrorMessage: false
        });
    }

    render() {
        const {
            fields,
            errors,
            showLoader,
            showLoginErrorMessage,
            loginErrorMessage,
            forgotPassword,
            showForgotPasswordSuccessMessage,
            forgotPasswordSuccessMessage,
            showForgotPasswordErrorMessage,
            forgotPasswordErrorMessage,
            homeBannerImage,
            isAuthorizedRoute
        } = _.cloneDeep(this.state);

        if (!isAuthorizedRoute) {
            return (
                <NotFoundPage />
            )
        }

        return (
            <Container className="login-page">
                {
                    (showLoginErrorMessage || showForgotPasswordErrorMessage || showForgotPasswordSuccessMessage || showForgotPasswordErrorMessage) ? this.closeErrorNotificationAfterSetTimeOut() : null
                }

                {
                    showLoginErrorMessage ? (
                        <Snackbar
                            message={loginErrorMessage}
                            open={true}
                            duration={4000}
                            closeSnackBar={this.onCloseSnackBar}
                            variant={'error'}
                        />
                    ) : null
                }

                {
                    showForgotPasswordErrorMessage ? (
                        <Snackbar
                            message={forgotPasswordErrorMessage}
                            open={true}
                            duration={4000}
                            closeSnackBar={this.onCloseSnackBar}
                            variant={'error'}
                        />
                    ) : null
                }

                {
                    showForgotPasswordSuccessMessage ? (
                        <Snackbar
                            message={forgotPasswordSuccessMessage}
                            open={true}
                            duration={4000}
                            closeSnackBar={this.onCloseSnackBar}
                            variant={'success'}
                        />
                    ) : null
                }

                <Grid container spacing={3} className="login-page-wrapper">
                    <Grid item xs={12} md={8} lg={8} className="login-img">
                        {
                            homeBannerImage ? (
                                <img src={homeBannerImage} alt='No found' style={{ maxWidth: "520px" }} />
                            ) : null
                        }
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} className="login-form">

                        {
                            forgotPassword ? (
                                <Grid container spacing={3}>
                                    <Grid item xs={12} className='page-back-row'>
                                        <div className='page-back--link'>
                                            <ArrowBackIosOutlinedIcon color='primary' fontSize='small' onClick={() => this.handleBack()} />
                                            <Typography color='primary' variant='h6' className="category-name">Forgot Password</Typography>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="User Name"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            required
                                            name='userName'
                                            onChange={e => this.handleChange(e, "userName")}
                                            value={fields["userName"]}
                                            helperText={errors['userName'].isError ? errors['userName'].errorMessage : ""}
                                            error={errors['userName'].isError}
                                            id="userName"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button variant="contained" color="primary" disabled={showLoader} onClick={() => this.sendLinkHandler()}>
                                            Submit
                                            {
                                                showLoader ? (
                                                    <Box className='CustLodder' padding={1}>
                                                        <CircularProgress size={20} />
                                                    </Box>
                                                ) : null
                                            }
                                        </Button>
                                    </Grid>
                                </Grid>
                            ) : (
                                <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography color='primary' variant='h6' className="category-name">Login To Your Account</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Button variant="contained" color="default" onClick={this.props.handleRedirect} className='office365-button'>
                                                Sign up
                                            </Button>
                                        </Grid>

                                    </Grid>
                                </form>
                            )
                        }

                    </Grid>
                </Grid>

            </Container>
        )
    }
}

const mapStateToProps = state => {
    const { LoginRecuder } = state;
    return {
        loggedInUserProfile: LoginRecuder.loggedInUserProfile
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchUserProfile: async params => await dispatch(fetchUserProfile(params))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(style)(Login)));