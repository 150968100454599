import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {
    Container,
    Grid,
    Paper,
    TextField,
    Button,
    Box,
    CircularProgress,
    Typography
} from '@material-ui/core';
import {
    fetchAllRequestCount
} from '../../components/CommonFunctions';
import {
    ALL_ROUTES,
    seriesColummList,
    dateFilter,
    FILTER_TYPES
} from '../../constants.js';

import _ from 'lodash';

am4core.useTheme(am4themes_animated);
const moment = require("moment")
const style = {
    paper: {
        width: '100%'
    },
    landingPageButtonWarpper: {
        display: 'flex',
        justifyContent: "flex-end",
        alignItems: "baseline"
    },
    dateFilter: {
        color: 'black'
    }
};

class LandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            yearList: [],
            selectedYear: null,
            showLoader: true,
            selectedDateFilter: dateFilter[0],
            selectedFilterType: FILTER_TYPES[0],
            selectedFromDate: "",
            selectedToDate: "",
            errors: {
                fromdate: {
                    isError: false,
                    errorMessage: ""
                },
                todate: {
                    isError: false,
                    errorMessage: ""
                }
            },
            finalSelectedDates: {
                fromdate: "",
                todate: "",
                filterType: FILTER_TYPES[0].label
            }
        }
        this.chart = null
        this.healthSystemwiseChart = null
    }

    async componentDidMount() {
        let yList = []
        const rangeYearList = _.range(process.env.MIN_YEAR, moment().year() + 1)
        for (let y of rangeYearList) {
            yList.push({
                label: y.toString(),
                value: y.toString()
            })
        }

        await this.setState({
            yearList: _.reverse(yList),
            selectedYear: yList[0],
            showLoader: true,
            selectedDateFilter: dateFilter[0]

        })
        await this.fetchAllRequestCountTemplate(yList[0].value)
    }

    fetchAllRequestCountTemplate = async (year) => {
        const {
            selectedFromDate,
            selectedToDate,
            selectedDateFilter,
            selectedFilterType
        } = _.cloneDeep(this.state)

        let fromdate = null
        let todate = null
        let dateRange = null
        let finalSelectedDates = {}

        switch (selectedDateFilter.value) {
            case "All":
                finalSelectedDates.fromdate = "Start"
                finalSelectedDates.todate = "Present"
                break;
            case "30_Min":
                fromdate = moment().utc().subtract(30, 'minutes').format('YYYY-MM-DD HH:mm:ss')
                todate = moment().utc().format('YYYY-MM-DD HH:mm:ss')
                finalSelectedDates.fromdate = fromdate
                finalSelectedDates.todate = todate
                break;
            case "60_Min":
                fromdate = moment().utc().subtract(60, 'minutes').format('YYYY-MM-DD HH:mm:ss')
                todate = moment().utc().format('YYYY-MM-DD HH:mm:ss')
                finalSelectedDates.fromdate = fromdate
                finalSelectedDates.todate = todate
                break;
            case "8_Hours":
                fromdate = moment().utc().subtract(8, 'hours').format('YYYY-MM-DD HH:mm:ss')
                todate = moment().utc().format('YYYY-MM-DD HH:mm:ss')
                finalSelectedDates.fromdate = fromdate
                finalSelectedDates.todate = todate
                break;
            case "12_Hours":
                fromdate = moment().utc().subtract(12, 'hours').format('YYYY-MM-DD HH:mm:ss')
                todate = moment().utc().format('YYYY-MM-DD HH:mm:ss')
                finalSelectedDates.fromdate = fromdate
                finalSelectedDates.todate = todate
                break;
            case "Today":
                dateRange = this.getDateRange(0)
                fromdate = dateRange.startDate
                todate = dateRange.endDate
                finalSelectedDates.fromdate = fromdate
                finalSelectedDates.todate = todate
                break;
            case "Four_Days":
                dateRange = this.getDateRange(4)
                fromdate = dateRange.startDate
                todate = dateRange.endDate
                finalSelectedDates.fromdate = fromdate
                finalSelectedDates.todate = todate
                break;
            case "Seven_Days":
                dateRange = this.getDateRange(7)
                fromdate = dateRange.startDate
                todate = dateRange.endDate
                finalSelectedDates.fromdate = fromdate
                finalSelectedDates.todate = todate
                break;
            default:
                break;
        }

        if (selectedDateFilter && selectedDateFilter.value === "Custom") {
            if (selectedFromDate) {
                fromdate = moment(selectedFromDate).startOf('day').format("YYYY-MM-DD HH:mm:ss")
            }

            if (selectedToDate) {
                todate = moment(selectedToDate).endOf('day').format("YYYY-MM-DD HH:mm:ss")
            }

            finalSelectedDates.fromdate = fromdate ? fromdate : "Start"
            finalSelectedDates.todate = todate ? todate : "Present"
        }

        finalSelectedDates.fromdate = (finalSelectedDates.fromdate.includes("Start") || finalSelectedDates.fromdate.includes("Present")) ? finalSelectedDates.fromdate : `${finalSelectedDates.fromdate} UTC`
        finalSelectedDates.todate = (finalSelectedDates.todate.includes("Start") || finalSelectedDates.todate.includes("Present")) ? finalSelectedDates.todate : `${finalSelectedDates.todate} UTC`
        finalSelectedDates.filterType = selectedFilterType.value;

        const responseData = await fetchAllRequestCount(fromdate, todate, selectedFilterType.value);
        await this.setState({
            showLoader: false,
            finalSelectedDates
        })
        // await this.createChart("chartdiv", responseData.requestCountList, "count", "month", this.chart, "Count")
        await this.createChart("healthSystemwiseChartDiv", responseData.healthSystemwiseSummaryList, "totalCount", "name", this.chart, "totalCount")
    }

    createChart = async (div, responseData, valueY, categoryX, chartObject, seriesName) => {
        const countList = _.map(responseData, valueY)
        let chart = am4core.create(div, am4charts.XYChart);
        chart.logo.disabled = true

        chart.paddingRight = 20;

        chart.data = responseData

        //******************************************************************************************* */

        // Add legend
        chart.legend = new am4charts.Legend();
        chart.legend.position = "bottom";

        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "healthSystemName";

        if (this.state.selectedFilterType.value === "PURPOSE_OF_USE_WISE") {
            categoryAxis.title.text = "Purpose of uses";
        }

        if (this.state.selectedFilterType.value === "EHR_WISE") {
            categoryAxis.title.text = "EHRs";
        }

        if (this.state.selectedFilterType.value === "LOCATION_WISE") {
            categoryAxis.title.text = "Health System Locations";
        }

        if (this.state.selectedFilterType.value === "FACILITY_WISE") {
            categoryAxis.title.text = "Health Systems";
        }

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        valueAxis.title.text = "Requests";
        valueAxis.min = 0;
        valueAxis.strictMinMax = true
        valueAxis.renderer.labels.template.fontSize = 10;

        _.each(seriesColummList, function (column) {
            var series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = column.field;
            series.dataFields.categoryX = "healthSystemName";
            series.name = column.name;
            series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
            series.columns.template.fillOpacity = 0.8;
            series.columns.template.width = am4core.percent(80);
            series.columns.template.fill = am4core.color(column.color)

            var bullet = series.bullets.push(new am4charts.LabelBullet());
            bullet.interactionsEnabled = false;
            bullet.dy = -10;
            // bullet.label.text = "{valueY}";
            // bullet.label.fill = am4core.color("#ffff");

            var valueLabel = series.bullets.push(new am4charts.LabelBullet());
            valueLabel.label.text = "{valueY}";
            valueLabel.label.fontSize = 10;
            valueLabel.label.verticalCenter = "bottom"

            return series;
        })

        // Adjust category spacing
        categoryAxis.renderer.cellStartLocation = 0.1; // Adjust as needed
        categoryAxis.renderer.cellEndLocation = 0.9;   // Adjust as needed
        categoryAxis.renderer.labels.template.fontSize = 10;

        //******************************************************************************************* */
        // // Create axes

        // var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        // categoryAxis.dataFields.category = categoryX;
        // categoryAxis.renderer.grid.template.location = 0;
        // categoryAxis.renderer.minGridDistance = 30;

        // categoryAxis.renderer.labels.template.adapter.add("dy", function (dy, target) {
        //     if (target.dataItem && target.dataItem.index & 2 == 2) {
        //         return dy + 25;
        //     }
        //     return dy;
        // });

        // var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        // valueAxis.max = _.max(countList) + 500

        // console.log(" _.max(countList) :::: ",  _.max(countList) + 50)

        // // Create series
        // var series = chart.series.push(new am4charts.ColumnSeries());
        // series.dataFields.valueY = valueY;
        // series.dataFields.categoryX = categoryX;
        // series.name = seriesName;
        // series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        // series.columns.template.fillOpacity = .8;

        // var valueLabel = series.bullets.push(new am4charts.LabelBullet());
        // valueLabel.label.text = "{valueY}";
        // valueLabel.label.fontSize = 20;
        // valueLabel.label.verticalCenter = "bottom"

        // var columnTemplate = series.columns.template;
        // columnTemplate.strokeWidth = 2;
        // columnTemplate.strokeOpacity = 1;

        // series.columns.template.events.on("hit", function (ev) {
        //     console.log("Event ::: ", ev.target.dataItem.categories.categoryX)
        // })

        chartObject = chart;

        // Add simple vertical 
        chart.scrollbarY = new am4core.Scrollbar();

        // Add horizotal scrollbar with preview
        var scrollbarX = new am4charts.XYChartScrollbar();
        // scrollbarX.series.push(series);

        scrollbarX.thumb.background.fill = am4core.color("#d9d9d9")
        scrollbarX.thumb.background.fillOpacity = 0.8
        scrollbarX.minHeight = 10

        if (this.state.selectedFilterType.value === "PURPOSE_OF_USE_WISE" || this.state.selectedFilterType.value === "EHR_WISE") {
            chart.scrollbarX = scrollbarX;
            chart.scrollbarX.start = 0;
            chart.scrollbarX.end = 3.0;
            chart.scrollbarX.parent = chart.bottomAxesContainer;
        }

        if (this.state.selectedFilterType.value === "LOCATION_WISE") {
            chart.scrollbarX = scrollbarX;
            chart.scrollbarX.start = 0;
            chart.scrollbarX.end = 0.07;
            chart.scrollbarX.parent = chart.bottomAxesContainer;
        }

        if (this.state.selectedFilterType.value === "FACILITY_WISE") {
            chart.scrollbarX = scrollbarX;
            chart.scrollbarX.start = 0;
            chart.scrollbarX.end = 0.20;
            chart.scrollbarX.parent = chart.bottomAxesContainer;
        }
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }

        if (this.healthSystemwiseChart) {
            this.healthSystemwiseChart.dispose();
        }
    }

    onYearChangeHandler = async (value) => {
        if (this.chart) {
            this.chart.dispose();
        }
        const selectedYear = value.value
        await this.setState({ selectedYear: value })
        await this.fetchAllRequestCountTemplate(selectedYear)
    }

    refreshChartButtonClickHandler = async () => {
        if (this.chart) {
            this.chart.dispose();
        }
        await this.setState({
            showLoader: true
        })
        const { selectedYear } = _.cloneDeep(this.state)
        await this.fetchAllRequestCountTemplate(selectedYear.value)
    }

    clearChartButtonClickHandler = async () => {
        if (this.chart) {
            this.chart.dispose();
        }
        await this.setState({
            showLoader: true,
            selectedDateFilter: dateFilter[0],
            selectedFilterType: FILTER_TYPES[0],
            selectedFromDate: "",
            selectedToDate: "",
            errors: {
                fromdate: {
                    isError: false,
                    errorMessage: ""
                },
                todate: {
                    isError: false,
                    errorMessage: ""
                }
            }
        })

        const { selectedYear } = _.cloneDeep(this.state)
        await this.fetchAllRequestCountTemplate(selectedYear.value)
    }

    onDateFilterChangeHandler = async (field, value) => {
        let {
            errors
        } = _.cloneDeep(this.state)

        if (field === "fromdate") {
            await this.setState({
                selectedFromDate: value
            })
        }

        if (field === "todate") {
            await this.setState({
                selectedToDate: value
            })
        }

        const {
            selectedFromDate,
            selectedToDate
        } = _.cloneDeep(this.state)

        if (moment(selectedFromDate).isAfter(moment())) {
            errors.fromdate.isError = true;
            errors.fromdate.errorMessage = "Date can not be in future."
        } else if (moment(selectedFromDate).isAfter(moment(selectedToDate))) {
            errors.fromdate.isError = true;
            errors.fromdate.errorMessage = "Date can not be greater than to date."
        } else {
            errors.fromdate.isError = false;
            errors.fromdate.errorMessage = ""
        }

        if (moment(selectedToDate).isAfter(moment())) {
            errors.todate.isError = true;
            errors.todate.errorMessage = "Date can not be in future."
        } else if (moment(selectedFromDate).isAfter(moment(selectedToDate))) {
            errors.todate.isError = true;
            errors.todate.errorMessage = "Date can not be less than from date."
        } else {
            errors.todate.isError = false;
            errors.todate.errorMessage = ""
        }

        await this.setState({
            errors
        })
    }

    getDateRange = (daysToAdd) => {
        // Get today's date
        const today = moment();

        // Calculate the start date by subtracting daysToAdd from today
        const startDate = today.clone().subtract(daysToAdd, 'days');

        // End date is today
        const endDate = today.clone();

        console.log("startDate :::: ", startDate.format('YYYY-MM-DD HH:mm:ss'))
        console.log("endDate :::: ", endDate.format('YYYY-MM-DD HH:mm:ss'))

        return {
            startDate: startDate.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss')
        };
    }

    gotoDetailViewButtonClickHandler = () => {
        this.props.history.push(ALL_ROUTES.DASHBOARD);
    }

    onSelectedDateFilterChangeHandler = async (value) => {
        const {
            selectedFromDate,
            selectedToDate
        } = _.cloneDeep(this.state)

        await this.setState({
            selectedDateFilter: value,
            selectedFromDate: value.value !== "Custom" ? "" : selectedFromDate,
            selectedToDate: value.value !== "Custom" ? "" : selectedToDate,
            errors: {
                fromdate: {
                    isError: false,
                    errorMessage: ""
                },
                todate: {
                    isError: false,
                    errorMessage: ""
                }
            }
        })
    }

    onSelectedFilterTypeChangeHandler = async (value) => {
        await this.setState({
            selectedFilterType: value,
        })
    }

    render() {
        const { classes } = this.props;
        const {
            yearList,
            selectedYear,
            showLoader,
            selectedDateFilter,
            selectedFromDate,
            selectedToDate,
            errors,
            finalSelectedDates,
            selectedFilterType
        } = _.cloneDeep(this.state)

        return (
            <Container className='main-container'>
                <Grid container className='all-resouces-list-wrapper' spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Paper className='respurce-list-paper'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={2}>
                                            <Autocomplete
                                                options={FILTER_TYPES}
                                                value={selectedFilterType}
                                                size='small'
                                                getOptionLabel={({ label }) => label}
                                                getOptionSelected={(option, value) => option.value === value.value}
                                                disableClearable={true}
                                                blurOnSelect
                                                onChange={(event, value) => this.onSelectedFilterTypeChangeHandler(value)}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Select Filter Type" variant="outlined" />
                                                )}
                                                className="autocomplete-field"
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={2}>
                                            <Autocomplete
                                                options={dateFilter}
                                                value={selectedDateFilter}
                                                size='small'
                                                getOptionLabel={({ label }) => label}
                                                getOptionSelected={(option, value) => option.value === value.value}
                                                disableClearable={true}
                                                blurOnSelect
                                                onChange={(event, value) => this.onSelectedDateFilterChangeHandler(value)}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Select Date Filter" variant="outlined" />
                                                )}
                                                className="autocomplete-field"
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                id="date"
                                                label='From Date'
                                                type="date"
                                                size="small"
                                                value={selectedFromDate}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                disabled={selectedDateFilter.value !== "Custom"}
                                                onChange={e => this.onDateFilterChangeHandler('fromdate', e.target.value)}
                                                helperText={errors['fromdate'].isError ? errors['fromdate'].errorMessage : ""}
                                                error={errors['fromdate'].isError}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                id="date"
                                                label='To Date'
                                                type="date"
                                                size="small"
                                                value={selectedToDate}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                disabled={selectedDateFilter.value !== "Custom"}
                                                onChange={e => this.onDateFilterChangeHandler('todate', e.target.value)}
                                                helperText={errors['todate'].isError ? errors['todate'].errorMessage : ""}
                                                error={errors['todate'].isError}
                                            />
                                        </Grid>

                                        <Grid item xs={4} md={4} className={classes.landingPageButtonWarpper}>
                                            <Button
                                                variant='outlined'
                                                color='primary'
                                                onClick={() => this.clearChartButtonClickHandler()}
                                                className='show-req-btn'
                                                title="Clear Filter"
                                                disabled={showLoader}
                                            >
                                                Clear
                                            </Button>

                                            <Button
                                                variant='contained'
                                                color='primary'
                                                onClick={() => this.refreshChartButtonClickHandler()}
                                                className='show-req-btn'
                                                title="Filter Chart"
                                                disabled={(errors['fromdate'].isError || errors['todate'].isError || showLoader) ? true : false}
                                            >
                                                Filter
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

                {
                    showLoader ? (
                        <Grid container className='all-resouces-list-wrapper' spacing={3}>
                            <Grid item xs={12} md={12}>
                                <Paper className='respurce-list-paper' style={{ height: '500px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={12}>
                                                    <div className='CustLodder circular-loader'>
                                                        <Box className='CustLodder' padding={1}>
                                                            <Box className={classes.textAlignCenter}>
                                                                <CircularProgress size={40} />
                                                            </Box>
                                                        </Box>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid >
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid >
                    ) : (
                        <Grid container className='all-resouces-list-wrapper' spacing={3}>
                            <Grid item xs={12} md={12}>
                                <Paper className='respurce-list-paper'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={9}>
                                                    <Typography color='primary' variant='h6' className="category-name">{finalSelectedDates.filterType} Summary - <span className={classes.dateFilter}>From {finalSelectedDates.fromdate} To {finalSelectedDates.todate}</span></Typography>
                                                </Grid>

                                                <Grid item xs={12} md={3} className={classes.landingPageButtonWarpper}>
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={() => this.gotoDetailViewButtonClickHandler()}
                                                        className='show-req-btn'
                                                        title="Detail View"
                                                    >
                                                        Go to Detail View
                                                    </Button>
                                                </Grid>

                                                <Grid item xs={12} md={12}>
                                                    <div id="healthSystemwiseChartDiv" style={{ width: "100%", height: "500px", paddingBottom: "1rem" }}></div>
                                                </Grid>
                                            </Grid>
                                        </Grid >
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid >
                    )
                }

            </Container >
        )
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(style)(LandingPage)));