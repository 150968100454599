import axios from 'axios';

import {
    API_GATEWAY_URL,
    GET_USER_PROFILE
} from '../../constants';

export const fetchUserProfileAPI = (params) => {
    return axios({
        method: 'GET',
        url: `${API_GATEWAY_URL}${GET_USER_PROFILE}`,
        'headers': {
            'content-type': 'application/json',
            'token': params
        }
    })
}